
import { User } from "@/store/user/types";
import { Vue, Component, VModel, Watch } from "vue-property-decorator";
import { ROOT_ACTIONS } from "@/store/actions";
import { USER_GETTERS } from "@/store/user/getters";
import { addDoc, collection, doc, getDoc, getDocs, query, updateDoc, where } from "firebase/firestore";
import { firestore } from "@/firebase";
import { Phase } from "@/types";

@Component
export default class CreateEventDialog extends Vue {
  @VModel({ required: true }) show!: boolean;

  participants: User[] = [];

  selected: null | User = null;

  loading = false;

  phases: Phase[] = [];

  get user(): User {
    return this.$store.getters[USER_GETTERS.ACTING_USER];
  }

  @Watch("selected", { deep: true })
  async onSelectedChanged() {
    if (this.selected) {
      const program = await getDocs(
        query(
          collection(firestore, "programs"),
          where("participant", "==", this.selected.id)
        )
      );

      if (!program.empty) {
        const phases = await getDocs(
          query(
            collection(firestore, "phases"),
            where("programId", "==", program.docs[0].id)
          )
        );
        this.phases = phases.docs.map((e) => {
          return {
            ...e.data(),
            id: e.id,
          };
        }) as Phase[];

        // find the phase where the current date is between the start and end date
        const currentDate = new Date();
        const current = this.phases.find(
          (e) =>
            new Date(e.start.toDate()) <= currentDate &&
            new Date(e.end.toDate()) >= currentDate
        );

        if(current) {
          this.selectedPhase = current.id!;
        }
      }
    }
  }

  selectedPhase = "";

  async getUsers() {
    const users = await getDocs(query(collection(firestore, "users")));
    return users.docs.map((e) => {
      return {
        ...e.data(),
        id: e.id,
        fullName: e.data().firstname + " " + e.data().lastname,
      } as User;
    });
  }

  async mounted() {
    let users: User[] = await this.getUsers();

    if (this.user.type !== "ADMIN") {
      const programs = await getDocs(
        query(
          collection(firestore, "programs"),
          where("coach", "==", this.user.id)
        )
      );
      const myParticipants = programs.docs.map((e) => e.data().participant);
      users = users.filter((e) => myParticipants.includes(e.id));
    }

    this.participants = users;
  }

  resetFields() {
    this.selected = null;
  }

  async submit() {
    try {
      this.loading = true;

      if (!this.selected) return;

      let coach = "";

      if (this.user.type === "ADMIN") {
        const programsOfUser = await getDocs(
          query(
            collection(firestore, "programs"),
            where("participant", "==", this.selected.id)
          )
        );
        if (!programsOfUser.empty) {
          coach = programsOfUser.docs[0].data().coach as string;
        } else {
          coach = this.user.id;
        }
      } else {
        coach = this.user.id;
      }

      // check if user is already registered
      const querySnapshot = await getDocs(
        query(
          collection(firestore, "event-attendees"),
          where("webinarId", "==", this.$route.params.id),
          where("user", "==", this.selected.id)
        )
      );
      if (!querySnapshot.empty) {
        this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
          color: "error",
          text: this.$t("participantAlreadyRegistered"),
        });
        return;
      }

      const attendeeDoc = await addDoc(collection(firestore, "event-attendees"), {
        webinarId: this.$route.params.id,
        user: this.selected.id,
        coach,
        present: false,
        addedActivity: false,
      });

      await addDoc(collection(firestore, "webinar-invite-test"), {
        user: this.selected.id,
        webinar: this.$route.params.id,
      });

      const activitiesInPhase = await getDocs(query(collection(firestore, 'activities'), where('phaseId', '==', this.selectedPhase)));
      const activity = activitiesInPhase.docs.find(doc => doc.data().name.toLowerCase().includes('webinar'));
      const webinar = await getDoc(doc(firestore, 'webinars', this.$route.params.id));

      if (activity && webinar.exists()) {
            // Add the actual activity
            const actualActivityDoc = await addDoc(collection(firestore, 'actual-activities'), {
                actualHours: 2,
                comment: `Webinar: ${webinar.data().title}`,
                activityId: activity.id,
                date: webinar.data().start,
                participantConfirmed: true,
                participantPresent: false,
                skipEmail: true,
            });

            await updateDoc(doc(firestore, 'event-attendees', attendeeDoc.id), {
                actualActivityId: actualActivityDoc.id,
            });
        } else {
            this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
                color: 'orange',
                text: 'No webinar activity found in this phase',
            });
        }

      this.$emit("refresh");
      this.resetFields();
      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: "success",
        text: this.$t("participantAlreadyRegistered"),
      });
      this.show = false;
    } catch (error) {
      console.error(error);
      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: "error",
        text: "Error",
      });
    } finally {
      this.loading = false;
    }
  }
}
